import React from "react"
import Container from "react-bootstrap/Container"

const SimplePage = ({ children }) => (
  <section className="pt-8 pt-md-11 pb-8 pb-md-12" style={{ backgroundColor: "#f6f8fb" }}>
    <Container>{children}</Container>
  </section>
)

export default SimplePage
