import classNames from "classnames"
import React from "react"

const PageHeading = ({ className, children, ...props }) => (
  <h3 className={classNames("pt-6 pt-md-8 mb-5", className)} {...props}>
    {children}
  </h3>
)

export default PageHeading
