import classNames from "classnames"
import React from "react"

const PageParagraph = ({ className, children, ...props }) => (
  <p className={classNames("font-size-lg text-gray-800 pb-4 mb-0", className)} {...props}>
    {children}
  </p>
)

export default PageParagraph
