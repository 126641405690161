import React from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"

const PageMain = ({ aside, content }) => (
  <Row>
    <Col md="4" className="d-none d-md-block mt-6 mt-md-8">
      {aside}
    </Col>
    <Col md="8" className="mt-6 mt-md-9">
      {content}
    </Col>
  </Row>
)

export default PageMain
