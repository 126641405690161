import React from "react"
import PageHeading from "./PageHeading"

const PageContent = ({ content }) => (
  <>
    {content.map(({ heading, paragraphs }, index) => (
      <div id={`heading-${index}`} key={`content-heading-${index}`}>
        <PageHeading>
          {++index}. {heading}
        </PageHeading>
        {paragraphs}
      </div>
    ))}
  </>
)

export default PageContent
